import gql from 'graphql-tag'


export const LIST_PRINTER = (templateType) => gql`query LIST_PRINTER ($docType: String!) {
  printers: list${templateType}Printer (docType: $docType) {
    id type code name
  }
}`

export const LIST_REPORT_LEDGER = (templateType) => gql`query LIST_REPORT_LEDGER ($docType: String!, $filter: LedgerReportFilterInput!, $options: LedgerReportOptionsInput) {
  report: list${templateType}ReportLedger (docType: $docType, filter: $filter, options: $options) {
    summary doc
  }
}`

export const LIST_REPORT_INVENTORY = (templateType) => gql`query LIST_REPORT_INVENTORY ($docType: String!, $filter: InventoryReportFilterInput!, $options: InventoryReportOptionsInput) {
  report: list${templateType}ReportInventory (docType: $docType, filter: $filter, options: $options) {
    summary doc
  }
}`

export const LIST_REPORT_GROUP = (templateType) => gql`query LIST_REPORT_GROUP ($docType: String!, $startDate: DateTime!, $endDate: DateTime!, $categoryId: Int) {
  items: list${templateType}ReportGroup (docType: $docType, startDate: $startDate, endDate: $endDate, categoryId: $categoryId)
}`

export const SYNC_REPORT_GROUP = (templateType) => gql`mutation SYNC_REPORT_GROUP ($docType: String!, $month: String!) {
  result: sync${templateType}ReportGroup (docType: $docType, month: $month)
}`
